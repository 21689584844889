@import "../../styles/_variables";
@import "../../styles/_mixins";

.spinner {
  font-size: 1em;

  @include mq("tablet") {
    font-size: 0.8em;
  }

  &.tiles {
    height: 2.5em;
    text-align: center;

    .rect {
      display: inline-block;
      height: 100%;
      width: 0.375em;
      margin: 0 0.125em;
      background-color: $secondary;
      animation: sk-stretchdelay 1.2s infinite ease-in-out;

      &.rect2 {
        animation-delay: -1.1s;
      }

      &.rect3 {
        animation-delay: -1s;
      }

      &.rect4 {
        animation-delay: -0.9s;
      }

      &.rect5 {
        animation-delay: -0.8s;
      }
    }
  }

  &.circle {
    width: 3.5em;
    height: 3.5em;
    border: 0.5em solid transparent;
    border-top-color: $secondary;
    border-radius: 50%;
    position: relative;
    animation: loader-rotate 1s linear infinite;
    top: 50%;
    margin: -1.75em auto 0;

    &.inButton {
      width: 1.5em;
      height: 1.5em;
      margin: 0;
      border-width: 0.2em;
    }
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
