// COLORS
$primary: #3a4147;
$primary-darker: #2a363b;

$secondary: #f0f0f0;

$red-accent: #f14c3d;
$red-accent-hover: #dd3829;
$green-accent: #15cf67;
$green-accent-hover: #01bb52;
$yellow-accent: #ffc312;
$grey-accent: #606d78;
$grey-accent-hover: 4c5964;

$google: #ea4335;
$google-hover: #d62f21;
$microsoft: #0066bf;
$microsoft-hover: #0053ab;

$black: #000;

$avatar-bg: (#706fd3, #f7b731, #2d98da, #ff5e57, #20bf6b);

// GRADIENTS
$radial-bg: radial-gradient(
  50% 50% at 50% 50%,
  $primary 0%,
  $primary-darker 100%
);

// SHADOWS
$box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

$breakpoints: (
  "mobile": 480px,
  "tablet": 768px,
  "desktop": 1200px,
);
