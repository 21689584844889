@import "styles/variables";
@import "styles/mixins";

@font-face {
  font-family: "Arcon";
  src: url("assets/fonts/Arcon.otf");
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  position: relative;
  width: 100vw;
  height: 100vh;
  font-family: "Arcon", Arial, Helvetica, sans-serif;
  font-size: 100%;
  scroll-behavior: smooth;
}

.rc-switch {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 2.75em;
  height: 1.375em;
  line-height: 1.25em;
  padding: 0;
  vertical-align: middle;
  border-radius: 1.25em 1.25em;
  border: 0.0625em solid $primary;
  background-color: $primary;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);

  .rc-switch.darker {
    border: 0.0625em solid $primary-darker;
    background-color: $primary-darker;
  }
}
.rc-switch-inner {
  color: $secondary;
  position: absolute;
  left: 1.5em;
  top: 0;
}
.rc-switch:after {
  position: absolute;
  width: 1.125em;
  height: 1.125em;
  left: 0.125em;
  top: 0.0625em;
  border-radius: 50% 50%;
  background-color: $secondary;
  content: " ";
  cursor: pointer;
  box-shadow: $box-shadow;
  transform: scale(1);
  transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  animation-duration: 0.3s;
  animation-name: rcSwitchOff;
}
.rc-switch:hover:after {
  transform: scale(1.1);
  animation-name: rcSwitchOn;
}
.rc-switch:focus {
  outline: none;
}
.rc-switch-checked {
  border: 0.0625em solid $green-accent;
  background-color: $green-accent;
}
.rc-switch-checked .rc-switch-inner {
  left: 0.375em;
}
.rc-switch-checked:after {
  left: 1.375em;
}
.rc-switch-disabled {
  cursor: no-drop;
  background: #ccc;
  border-color: #ccc;
}
.rc-switch-disabled:after {
  background: #9e9e9e;
  animation-name: none;
  cursor: no-drop;
}
.rc-switch-disabled:hover:after {
  transform: scale(1);
  animation-name: none;
}
.rc-switch-label {
  display: inline-block;
  line-height: 1.25em;
  padding-left: 0.625em;
  vertical-align: middle;
  white-space: normal;
  pointer-events: none;
  user-select: text;
}

@keyframes rcSwitchOn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1.1);
  }
}
@keyframes rcSwitchOff {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.rc-slider {
  position: relative;
  //height: 0.875em;
  //padding: 0.3125em 0;
  width: 100%;
  border-radius: 0.375em;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.rc-slider.bg-darker {
  .rc-slider-rail {
    background-color: $primary-darker;
  }
}

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: $primary;
  height: 0.75em;
  border-radius: 0.375em;
}
.rc-slider-track {
  position: absolute;
  left: 0;
  height: 0.75em;
  border-radius: 0.375em;
  background-color: $green-accent;
}
.rc-slider-handle {
  position: absolute;
  width: 1.3em;
  height: 1.3em;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -0.3em;
  cursor: grab;
  border-radius: 50%;
  background-color: $secondary;
  touch-action: pan-x;
  box-shadow: $box-shadow;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: $green-accent;
  box-shadow: 0 0 0 0.3125em $green-accent;
}
.rc-slider-handle:focus {
  outline: none;
}
.rc-slider-handle-click-focused:focus {
  border-color: $green-accent;
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: $green-accent;
}
.rc-slider-handle:active {
  //border-color: $green-accent;
  //box-shadow: 0 0 0.3125em $green-accent;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.rc-slider-mark {
  position: absolute;
  top: 1.125em;
  left: 0;
  width: 100%;
  font-size: 0.75em;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: $secondary;
  max-width: 7em;
  margin-top: 1em;
  font-size: 1.9em;
}

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 0.25em;
  background: transparent;
}
.rc-slider-dot {
  display: none;
  position: absolute;
  bottom: -0.125em;
  margin-left: -0.25em;
  width: 0.5em;
  height: 0.5em;
  border: 0.125em solid #e9e9e9;
  background-color: $secondary;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
.rc-slider-dot-active {
  border-color: $green-accent;
}
.rc-slider-dot-reverse {
  margin-right: -0.125em;
}
.rc-slider-disabled {
  background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: $secondary;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 0.875em;
  height: 100%;
  padding: 0 0.3125em;
}
.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 0.25em;
}
.rc-slider-vertical .rc-slider-track {
  left: 0.3125em;
  bottom: 0;
  width: 0.25em;
}
.rc-slider-vertical .rc-slider-handle {
  margin-left: -0.3125em;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 1.125em;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 0.25em;
}
.rc-slider-vertical .rc-slider-dot {
  left: 0.125em;
  margin-bottom: -0.25em;
}
.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -0.25em;
}
.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -0.25em;
}

.reset-select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;

  &::-ms-expand {
    display: none;
  }
}

@for $i from 0 to length($avatar-bg) {
  .avatar-bg-color-#{($i + 1)} {
    background-color: nth($avatar-bg, $i + 1) !important;
  }
}

.spinner-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    $primary 0%,
    $primary-darker 100%
  );
  font-size: 1.2em;
}

.IIV::-webkit-media-controls-play-button,
.IIV::-webkit-media-controls-start-playback-button {
  opacity: 0;
  pointer-events: none;
  width: 5px;
}

.DayPickerInput-OverlayWrapper {
  margin-top: 1em;

  .DayPickerInput-Overlay {
    background-color: transparent !important;

    .DayPicker {
      background-color: $primary;
      border-radius: 0.4em;
      box-shadow: $box-shadow;

      .DayPicker-Day {
        cursor: pointer;
        transition: color 250ms ease;
      }

      .DayPicker-Day:hover {
        color: $green-accent;
        background-color: transparent !important;
      }

      .DayPicker-Day--today {
        color: $green-accent;
      }

      .DayPicker-Day--selected {
        color: #fff;
        background-color: $green-accent !important;
      }
    }
  }
}

.rc-time-picker-panel-select {
  background-color: $secondary !important;
}
