@import "./_variables";
@import "./_mixins";

/* .Toastify {
  .Toastify__toast_container {
    width: 160px;
    bottom: 20px;
    position: absolute;

    .Toastify__toast {
      background: #394147;
      box-shadow: 0 0 10px -6px #000000c9;
      border-radius: 10px;
      color: #ffffffbf;

      .Toastify__toast-body {
        padding: 10px 15px;
      }

      .Toastify__close-button {
        display: none;
      }
      .Toastify__progress-bar {
        height: 5px;
        background: green;
      }
    }
  }
} */

.Toastify {
  padding: 0 2.8125rem;
  position: absolute;
  left: 0;
  bottom: 5em;
  z-index: 1000;
}

/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
  //width: 260px;
  bottom: 20px;
  position: absolute;
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
}
.Toastify__toast-container--top-center {
}
.Toastify__toast-container--top-right {
}
.Toastify__toast-container--bottom-left {
}
.Toastify__toast-container--bottom-center {
}
.Toastify__toast-container--bottom-right {
}

/** Classes for the displayed toast **/
.Toastify__toast {
  position: relative;
  background: $primary;
  //box-shadow: 0 0 10px -6px #000000c9;
  box-shadow: $box-shadow;
  margin-top: 10px;
  border-radius: 0.4em;
  color: #ffffffbf;
  min-height: 70px;
  min-width: 270px;
}
.Toastify__toast--rtl {
}
.Toastify__toast--dark {
}
.Toastify__toast--default {
}
.Toastify__toast--info {
}
.Toastify__toast--success {
}
.Toastify__toast--warning {
}
.Toastify__toast--error {
  .Toastify__progress-bar {
    background: $red-accent;
  }
}
.Toastify__toast-body {
  padding: 15px 25px 25px 25px;
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  border: none;
  padding: 5px 8px;

  opacity: 0.75;
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
  width: 12px;
  fill: $secondary;
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
  opacity: 1;
}

/** Classes for the progress bar **/
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5px;
  background: $green-accent;
}
.Toastify__progress-bar--animated {
}
.Toastify__progress-bar--controlled {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar--default {
}
.Toastify__progress-bar--dark {
}
